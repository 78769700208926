import React from "react";

export const DatenschutzPageTemplate = ({ html }) => {
  return (
    <section class="px-20p sm:px-10 my-50p sm:my-80p">
      <main
        class="markdown max-w-900p mx-auto"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </section>
  );
};
